import React, { useState } from 'react';
import Layout from "../components/layouts";
import styled, { createGlobalStyle } from 'styled-components'
import header from "../images/header-sustainable-gift.jpg";
import polar from "../images/sustainable-gift-polar-bear.png";
import ornaments from "../images/sustainable-gift-ornaments.jpg";
import card1 from "../images/sustainable-gift-1.jpg";
import card2 from "../images/sustainable-gift-2.jpg";
import card3 from "../images/sustainable-gift-3.jpg";
import ExpandButton from '../images/expand.svg'
import DownloadButton from '../images/download.svg'
import GalleryModal from '../components/GalleryModal';

const HolidayTipStyle = createGlobalStyle`
body {
  background: #02255C;
  color: #fff;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
}
`;

const StyledContainer = styled.div`
width: 100%;
max-width: 1200px;
margin: 0 auto;
padding: 0 34px;
`;

const StyledHeaderImage = styled.img`
width: 100%;
margin-top: 75px;
`;

const StyledFlex = styled.div`
display: flex;
flex-flow: row wrap;
margin-top: 60px;
font-size: 14px;

 @media(min-width: 768px) {
  font-size: 16px;
}
`;

const LeftContent = styled.div`
width: 100%;

a {
  color: #fff;
  font-weight: bold;

  &::after {
    opacity: 1;
    background-color: #fe1974;
  }

  &:hover {
    color: #fe1974;
  }
}

@media(min-width: 768px) {
  width: 70%;
}
`;

const RightContent = styled.div`
display: none;

a {

  &:hover {
    &::after {
      display: none;
    }
  
  }
}

@media(min-width: 768px) {
  width: 30%;
  display: flex;
  flex-flow: column;
  align-items: center;

  h3 {
    font-size: 24px;
    text-align: center;
    padding: 0 24px;
  }
}
`;

const StyledPolarBears = styled.img`
width: 90%;
margin-bottom: 24px;
margin-top: -24px;
`;

const StyledOrnaments = styled.img`
width: 100%;
`;

const StyledButton = styled.button`
padding: 16px;
border-radius: 20px;
color: #fff;
border: none;
background: #fe1974;
font-weight: bold;
font-size: 24px;
margin: 24px 0;
`;

const StyledCards = styled.div`
display: flex;
flex-flow: row wrap;
justify-content: space-between;
margin: 60px 0;

`;

const Card = styled.div`
  width: 100%;
  background: none;
  margin-bottom: 100px;

  &:hover {
    &::after {
      display: none;
    }
  }

  @media(min-width: 768px) {
    width: 32%;
    height: 500px;
    margin-bottom: 0;
    overflow: hidden;

  }
`; 

const RightContentMobile = styled.div`
width: 100%;
display: flex;
flex-flow: column;
align-items: center;
margin: 60px 0;

h3 {
  font-size: 24px;
  text-align: center;
  padding: 0 24px;
}
@media(min-width: 768px) {
  display: none;
}
`;


const StyledRolloverContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 50px;
  background: rgba(0,0,0,0.35);
  top: 100%;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;

  @media(min-width: 769px) {
    height: 100%;
    top: 0;
    opacity: 0;
  }

  &:hover {
    opacity: 1;
  }
`;

const StyledRolloverButton = styled.div`
width: 35px;
height: 35px;
margin: 5px 15px;
cursor: pointer;

@media(min-width: 769px) {
  width: 50px;
  height: 50px;
}

img {
  width: 100%;
  border: none;
}
`;

const StyledImageContainer = styled.div`
position: relative;
height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
`;

const HolidayTips = () => {

  return (
    <Layout navBg>
      <HolidayTipStyle />
      <StyledContainer>
        <StyledHeaderImage src={header} />
        <StyledFlex>
          <LeftContent>
            <p>
            Every gift box could use a card, and some gifts are the card themselves! For those looking for just the right card to express themselves, we've got you covered.  
            </p>
            <p>
            Our <strong>2024 “Polar Bear Approved” Card Collection</strong> celebrates some important themes close to our holiday hearts, and they’ve been illustrated by three creative forces close to our mission.
            </p>
            <p>
            <strong>DOWNLOAD THE PRINTABLE + SHAREABLE CARDS BELOW</strong>
            </p>
            <p>
            Artist <a href="https://www.instagram.com/gracehyein.park/" target="_blank" rel="noreferrer noopener">Grace Park</a> card reminds us of the joy and connection that comes with buying from local shops, and supporting members of your community.
            </p>
            <p>
            Artist <a href="https://www.instagram.com/lanusca" target="_blank" rel="noreferrer noopener">Lanusca's</a> card shines a light on the joy of discovery that comes with shopping for pre-loved finds at thrift stores. A second-hand item in the hands of your loved ones is twice as nice.
            </p>
            <p>
            Artist <a href="https://www.instagram.com/lukeymcgarry" target="_blank" rel="noreferrer noopener">Luke Mcgarry</a> is reminding us to invest our support in the most vulnerable members of our community - the animals who depend on the kindness and commitment of local welfare organizations.
            </p>
          </LeftContent>
          <RightContent>
            <StyledPolarBears src={polar} />
            <h3>Support Global Inheritance for the holidays!</h3>
            <a href="/donate">
            <StyledButton>
              DONATE NOW
            </StyledButton>
            </a>
            
            <StyledOrnaments src={ornaments} />
          </RightContent>
        </StyledFlex>
        <StyledCards>
          <Card>
          <StyledImageContainer>
              <img src={card1} />
            <StyledRolloverContainer>
             
              <StyledRolloverButton>
                <a href="https://app.box.com/s/481wawgsk904hk0o9yc7cut8m3iflnm2/file/1725759099893" target='_blank'>
                  <img src={DownloadButton} />
                </a>
              </StyledRolloverButton>
            </StyledRolloverContainer>
          </StyledImageContainer>
          </Card>
          <Card>
            <StyledImageContainer>
              <img src={card2} />
            <StyledRolloverContainer>
              
              <StyledRolloverButton>
                <a href="https://app.box.com/s/481wawgsk904hk0o9yc7cut8m3iflnm2/file/1725758848653" target='_blank'>
                  <img src={DownloadButton} />
                </a>
              </StyledRolloverButton>
            </StyledRolloverContainer>
          </StyledImageContainer>
          </Card>
          <Card>
          <StyledImageContainer>
              <img src={card3} />
            <StyledRolloverContainer>
              
              <StyledRolloverButton>
                <a href="https://app.box.com/s/481wawgsk904hk0o9yc7cut8m3iflnm2/file/1725760336316" target='_blank'>
                  <img src={DownloadButton} />
                </a>
              </StyledRolloverButton>
            </StyledRolloverContainer>
          </StyledImageContainer>
          </Card>

        </StyledCards>
        <RightContentMobile>
        <StyledPolarBears src={polar} />
            <h3>Support Global Inheritance for the holidays!</h3>
            <StyledButton>
              DONATE NOW
            </StyledButton>
            <StyledOrnaments src={ornaments} />
        </RightContentMobile>
      </StyledContainer>

   
    </Layout>
);
}

export default HolidayTips;


